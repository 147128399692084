import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import EntryFold from "../general/entryFold";
import Illustration from "../../../../assets/img/kuda-new-about.png";
import About1 from "../../../../assets/img/blur/kuda-new-about.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import Testimonials from "../home/sections/testimonials";
import OurStory from "./sections/ourStory";
import { Img } from "react-image";
const entryContent = {
    title: "About Kuda",
    subtitle: "We came from frustration with excessive bank charges (for inconsistent services), now we’re here.",
    illustration: <Img src={Illustration}/>

}

const AboutUs = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <EntryFold title={entryContent.title} subtitle={entryContent.subtitle} illustration={entryContent.illustration} isImage={true} fallback={<About1 className="blur isImage" />} />
            <OurStory />
            <Testimonials />
            <CTA />
        </Fragment>
    )
}

export default AboutUs;
